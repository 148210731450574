import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import tfinans1 from "../../assets/img/tfinans1.jpg"
import tfinans2 from "../../assets/img/tfinans2.png"
import tfinans3 from "../../assets/img/tr-finans-3.png"
import tfinans4 from "../../assets/img/tr-finans-4.png"

export default function TFinansProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Türkiye Finans Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={tfinans1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("turkiye-finans")}
            <br />
            <span>{t("project-sector")}</span>: {t("finans")}
            <br />
            <span>{t("project-subject")}</span>: {t("vip-takvim")} - 2011
            <br />
            <span>{t("project-artworkTR")}</span> {t("yedi-adet-hatTR")}
          </div>
          <img src={tfinans2} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={tfinans3} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("turkiye-finans")}
            <br />
            <span>{t("project-sector")}</span>: {t("finans")}
            <br />
            <span>{t("project-subject")}</span>: {t("vip-hediyelik")}
          </div>
          <img src={tfinans4} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
